import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Link } from 'gatsby';

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const CaseStudies = () => {
	const data = useStaticQuery(graphql`
	query {
		go: file(relativePath: { eq: "portfolio/cover-goapps.png" }) {
			childImageSharp {
				fluid(maxWidth: 320, quality: 100) {
					...GatsbyImageSharpFluid_noBase64
				}
			}
		},
		atg: file(relativePath: { eq: "portfolio/cover-atg.png" }) {
			childImageSharp {
				fluid(maxWidth: 320, quality: 100) {
					...GatsbyImageSharpFluid_noBase64
				}
			}
		},
		cmpd: file(relativePath: { eq: "portfolio/cover-cmpd.png" }) {
			childImageSharp {
				fluid(maxWidth: 320, quality: 100) {
					...GatsbyImageSharpFluid_noBase64
				}
			}
		},
		sf: file(relativePath: { eq: "portfolio/cover-sf.png" }) {
			childImageSharp {
				fluid(maxWidth: 320, quality: 100) {
					...GatsbyImageSharpFluid_noBase64
				}
			}
		},
		zapoint: file(relativePath: { eq: "portfolio/cover-zapoint.png" }) {
			childImageSharp {
				fluid(maxWidth: 320, quality: 100) {
					...GatsbyImageSharpFluid_noBase64
				}
			}
		},
		mb: file(relativePath: { eq: "portfolio/cover-mb.png" }) {
			childImageSharp {
				fluid(maxWidth: 320, quality: 100) {
					...GatsbyImageSharpFluid_noBase64
				}
			}
		},
		fh: file(relativePath: { eq: "portfolio/cover-fh.png" }) {
			childImageSharp {
				fluid(maxWidth: 320, quality: 100) {
					...GatsbyImageSharpFluid_noBase64
				}
			}
		},
		identity: file(relativePath: { eq: "portfolio/cover-identity.png" }) {
			childImageSharp {
				fluid(maxWidth: 320, quality: 100) {
					...GatsbyImageSharpFluid_noBase64
				}
			}
		}
	}
`)

	return(
		<Layout>
			<SEO title="Case studies" />
			<div className="wrapper">
				<div className="item">
					<div className='img'>
						<Img fluid={data.go.childImageSharp.fluid} />
					</div>
					<Link to='/case-studies/go-apps' className="title"><h4>Go Apps</h4></Link>
				</div>
				<div className="item">
					<div className='img'>
						<Img fluid={data.atg.childImageSharp.fluid} />
					</div>
					<Link to='/case-studies/twymanghoshal' className="title"><h4>twymanghoshal.com</h4></Link>
				</div>
				<div className="item">
					<div className='img'>
						<Img fluid={data.cmpd.childImageSharp.fluid} />
					</div>
                    <Link to='/case-studies/maritimepiracy' className="title"><h4>maritimepiracy.com</h4></Link>
				</div>
				<div className="item">
					<div className='img'>
						<Img fluid={data.sf.childImageSharp.fluid} />
					</div>
                    <Link to='/case-studies/scientific-financial' className="title"><h4>Scientific Financial</h4></Link>
				</div>

                <div className="item">
					<div className='img'>
						<Img fluid={data.zapoint.childImageSharp.fluid} />
					</div>
                    <Link to='/case-studies/zapoint' className="title"><h4>Zapoint</h4></Link>
				</div>

                <div className="item">
					<div className='img'>
						<Img fluid={data.fh.childImageSharp.fluid} />
					</div>
                    <Link to='/case-studies/future-horizons' className="title"><h4>Future Horizons</h4></Link>
				</div>

                <div className="item">
					<div className='img'>
						<Img fluid={data.mb.childImageSharp.fluid} />
					</div>
                    <Link to='/case-studies/moneyball' className="title"><h4>Moneyball</h4></Link>
				</div>

                <div className="item">
					<div className='img'>
						<Img fluid={data.identity.childImageSharp.fluid} />
					</div>
                    <Link to='/case-studies/identity' className="title"><h4>Logo & Indentity</h4></Link>
				</div>
			</div>
		</Layout>
	)
}
	


export default CaseStudies
